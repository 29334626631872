@use 'base/colors' as *;

.dropdownmenu ul,
.dropdownmenu li {
	margin: 0 ;
	padding: 0;
}

.dropdownmenu ul {
	list-style: none;
	width: 100%;
}

.dropdownmenu {
	float: right;
	//margin-top: -31px;
  @media (max-width: 715px) {
    display: none;
  }
	li {
		float: left;
		position: relative;
		width:auto;
	}

	a {
		//color: $gray-dark;
		display: block;
		//text-align: center;
		text-decoration: none;
		//border-bottom: 2px solid $white;
		-webkit-transition: all .5s ease;
		-moz-transition: all .5s ease;
		-ms-transition: all .5s ease;
		-o-transition: all .5s ease;
		transition: all .5s ease;

		  @media (min-width: 995px) {
				padding: 10px 25px 10px 25px
		  }
		  @media (max-width: 994px) {
		    padding: 10px 20px;
		  }

		  @media (max-width: 847px) {
		    padding: 10px 10px;
		  }

		  @media (max-width: 744px) {
		    padding: 10px 5px;
		  }
		}

}

.activeLink {
  color: $primary-green !important;
	//border-bottom-color: $primary-green !important;
}

.active {
  color: $primary-green !important;
	//border-bottom-color: $primary-green !important;
}

.dropdownmenu li:hover a{
  color: $primary-green;
	//border-bottom-color: $primary-green;
  //background: $red;
}
