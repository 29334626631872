@use 'base/colors' as *;

// Mixins
@mixin transform($value) {
  -webkit-transform: translate3d(0, 0, 0) $value;
  -moz-transform: translate3d(0, 0, 0) $value;
  -ms-transform: translate3d(0, 0, 0) $value;
  transform: translate3d(0, 0, 0) $value;
}

@mixin transition($value) {
  -webkit-transition: $value;
  -moz-transition: $value;
  -ms-transition: $value;
  transition: $value;
}

.fa {
  color: $primary-green;
}

.iMobile {
  font-size: 2em !important;
  color: $primary-green;

}


.toggle-menu {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  position: fixed;
  z-index: 2;
  
  @media (min-width: 716px) {
    display: none;
  }

  @media (max-width: 480px) {
    left: 10px;
    top: 15px;
  }

  @media (min-width: 480px) {
    left: 12px;
    top: 8px;
  }
}


#menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 240px;
  height: 100%;
  padding-top: 60px;
  background: $white;
  @include transform(translateX(-240px));
  @include transition(all .25s cubic-bezier(.16, .63, .45, .98));

  .toggle-menu {
    z-index: 3;
  }

  &.targetMobileMenu {
    @include transform(translateX(0));
    transform: translateX(0px);

  }

}

#menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

#menu.targetMobileMenu~#menu-overlay {
  display: block;
}

.hidden {
  display: none;
}

.lnk-menu {
  display: block;
  border-bottom: 1px solid $gray-light-bg ;
  margin: 0 10px;
  padding: 10px;
  color: $gray-dark;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: $primary-green;
  }
}


.lnk-menu-noAction {
  display: block;
  border-bottom: 1px solid $gray-light-bg ;
  margin: 0 10px;
  padding: 10px;
  color: $gray-dark;
  text-decoration: none;
}