@use 'base/colors' as *;

.wallpaper {
  background: url("./assets/home.jpg") center center;
  height: 596px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.none {
  visibility: hidden;
}


.cardHome {
  width: 20% !important;
  align-items: center !important;

  &:hover {
    background-color: $primary-green;

    .cardHomeContainer {
      svg {
        fill: $white;
      }

      h6 {
        color: $white;
      }

      p {
        color: $white;
      }
    }

    .heartCard {
      svg {
        fill: $primary-green;
        stroke: $white;
      }
    }
  }


  @media (max-width: 991px) {
    width: 20% !important;
  }

  @media (max-width: 768px) {
    width: 45% !important;
  }

  @media (max-width: 696px) {
    width: 95% !important;
  }
}

.container-center {
  text-align: center;
}

.container-center-70 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 70%;

  @media (max-width: 800px) {
    max-width: 95%
  }
}



.container-75 {
  max-width: 75%;

  @media (max-width: 1024px) {
    max-width: 90%
  }

  @media (max-width: 800px) {
    max-width: 95%
  }
}

.icons-home {
  font-size: 45px;
  padding: 10px;
}

.svg-icons {
  width: 68px;
  height: auto;
  padding: 10px;
  fill: $primary-green;
}

.svg-icons-small {
  margin-left: -4px;
  padding: 5px;
  height: 28px;
  fill: $primary-green;
}

.color-orange {
  fill: $primary-orange-2;
  color: $primary-orange-2;
}

.color-brown {
  fill: $primary-brown;
  color: $primary-brown;
}

.color-blue {
  fill: $primary-blue;
  color: $primary-blue;
}

.wrap-elements {
  display: inline-block;
  margin-right: 10px;
  text-align: center;
}

.wrap-lists {
  width: 80%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 20px;
  text-align: left;

  @media (max-width: 767px) {
    width: 95%
  }
}

.homeIcon {
  width: 50px;
  margin-left: 35%;
}

.homeInfo {
  padding-bottom: 15px;

  li {
    margin: 5px;
  }

  @media (min-width: 480px) {
    display: flex;
    flex-wrap: wrap;

    li {
      width: 30%;
    }
  }

}


/****  Green Banner ***/

.banner-green {
  padding: 50px;
  background-color: $primary-green;
  color: $white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-green .content {
  width: 50%;

  @media (max-width: 780px) {
    width: 90%;

  }

  p {
    text-align: center;
  }
}

.border-header {
  outline: 1px solid white;
}

.border-bottom-header {
  width: 250px;
  margin-top: 5px;
  margin-bottom: 10px;
  border-bottom: solid 2px $primary-green;
}

.border-bottom-wavy {
  text-decoration-line: line-through;
  text-decoration-style: wavy;
  -webkit-text-decoration-line: line-through;
  -webkit-text-decoration-style: wavy;
  font-size: 50px;
}

@keyframes scale {
  50% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

/****  Banners and pictures ***/

.banner-home-1,
.banner-home-2 {
  position: relative;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  height: 190px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 10px;
  animation: pulse 1s both infinite;

  .content {
    position: relative;
    z-index: 2;
    color: white;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    font-size: large;
    font-weight: bolder;
  }

}

.banner-home-1 {
  background: url("./assets/tratamiento.jpg") top center;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: rgba(0, 128, 0, 0.4);
    z-index: 1;

  }
}

.banner-home-2 {
  background: url("./assets/fertilidad.jpg") center center;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: rgba(189, 8, 183, 0.4);
    z-index: 1;

  }
}

.cardHomeContainer {
  h6 {
    text-transform: capitalize;
    color: $gray-dark;
  }

  p {
    color: $gray-light;
    font-size: 15px;
  }
}

.white-icon {
  color: #FFF !important;
}

.group-container {
  display: flex;
  display: -webkit-flex;
  justify-content: space-around;
  -webkit-justify-content: space-around;
  padding-bottom: 15px;

  li {
    cursor: pointer;
    border-bottom: 2px solid $gray-light-bg;
    padding: 0 20px;

    &:hover {
      color: $primary-orange;
      border-bottom: 2px solid $primary-orange;
    }

    &:active {
      color: $white;
      background-color: $primary-orange;
    }

    @media (max-width: 1024px) {
      padding: 5px 10px;
    }

  }

  @media (max-width: 680px) {
    display: none;
  }
}

.titleCard {
  padding-left: 20px;

  &.artcles {
    color: $gray-dark;
    background-color: $primary-yellow;
  }

  &.happy {
    color: $white;
    background-color: $primary-green;
  }
}

.homeCard {
  font-size: .9em;
  margin: 15px;


  @media(min-width: 768px) {
    width: 45%;
  }

  @media(min-width: 990px) {
    width: 30%;
  }

  .bt-crd {
    background-color: $gray-light;

    &:hover {
      background-color: $gray-eighty;
      color: $white;

      a {
        color: $black;
      }
    }

    a {
      color: $white;
    }
  }
}

.cardTestimonial {
  border: 1px solid $primary-green;
}


.floatRight {
  float: right !important;
  top: 0;
}

.important {
  background-color: $gray-eighty;
  padding: 10px;
}

.homeCardTest {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-direction: row;
  -webkit-flex-direction: row;
}

.cardTest {

  @media (min-width: 760px) {
    width: 45%;
    margin: 2.5%;
  }
}