@use 'base/colors' as *;

.carousel-container {
    width: 90%;
    padding-left: 10%;
    align-self: center;

    @media (max-width: 994px) {
        padding-left: 8%;
      }
}

.carousel-item img {
    width: 100px;
}

.slick-prev::before {
    color: $primary-green !important;
  }
  .slick-next::before {
    color: $primary-green !important;
  }